import styles from "./styles/SoundboardButton.module.scss"
import { useEffect, useState } from "react"

const SoundboardButton = ({ CurrentPlaying, name, start_playing, stop_playing }) => {

    const [isHover, setIsHover] = useState(false)


    function isPlaying(song_uuid) {
        if (CurrentPlaying[song_uuid] == undefined){
            return false
        }
        return !CurrentPlaying[song_uuid].paused;
    }


    return (<>
        <div id={name} onClick={(e) => { isPlaying(name) ? stop_playing(name) : start_playing(name) }} className={styles.button} style={{ borderColor: isPlaying(name) ? "rgba(82, 230, 88, 0.70)"/*vert*/  : isHover ? "rgba(82, 177, 230, 0.70)" : "" }} onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
            <div>
                {isPlaying(name) ? <img className={styles.stop_icon} src="/img/stop.svg" /> : <img src={isHover ? "/img/play.svg" : "/img/music_note.svg"} />}
                <div className={styles.label}><p className="no_user_select">{name}</p></div>
            </div>
        </div>
    </>)
}

export default SoundboardButton