// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poor+Story&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
}

body {
  min-height: 100vh;
  background-color: #17182f;
  color: white;
  text-align: center;
  font: normal normal 400 normal 40px "Poor Story", cursive;
  overflow-x: hidden;
}

img, .no_user_select {
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
}

.user_select {
  pointer-events: auto;
  user-select: auto;
  -webkit-user-select: auto;
  -khtml-user-select: auto;
  -moz-user-select: auto;
  -o-user-select: auto;
}

input {
  color: rgba(255, 255, 255, 0.8);
  font: normal normal 400 normal 40px "Poor Story", cursive;
}

input::placeholder {
  color: rgba(255, 255, 255, 0.4);
  font: normal normal 400 normal 40px "Poor Story", cursive;
}
@media only screen and (max-width: 670px) {
  input::placeholder {
    font-size: 30px;
  }
}

input:focus {
  outline: none;
}`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAKA;EACI,SAAA;AAHJ;;AAMA;EACI,iBAAA;EAEA,yBAAA;EAEA,YAAA;EACA,kBAAA;EACA,yDAbG;EAeH,kBAAA;AANJ;;AASA;EACI,oBAAA;EACA,iBAAA;EACA,yBAAA;EACA,wBAAA;EACA,sBAAA;EACA,oBAAA;AANJ;;AASA;EACI,oBAAA;EACA,iBAAA;EACA,yBAAA;EACA,wBAAA;EACA,sBAAA;EACA,oBAAA;AANJ;;AASA;EACI,+BAAA;EACA,yDAtCG;AAgCP;;AASA;EACI,+BAAA;EACA,yDA3CG;AAqCP;AAQI;EAJJ;IAKQ,eAAA;EALN;AACF;;AAQA;EACI,aAAA;AALJ","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Poor+Story&display=swap');\n\n$font-family: 'Poor Story', cursive;\n$font: normal normal 400 normal 40px $font-family;\n\n* {\n    margin: 0;\n}\n\nbody {\n    min-height: 100vh;\n\n    background-color: #17182f;\n\n    color: white;\n    text-align: center;\n    font: $font;\n\n    overflow-x: hidden;\n}\n\nimg, .no_user_select {\n    pointer-events: none;\n    user-select: none;\n    -webkit-user-select: none;\n    -khtml-user-select: none;\n    -moz-user-select: none;\n    -o-user-select: none;\n}\n\n.user_select {\n    pointer-events: auto;\n    user-select: auto;\n    -webkit-user-select: auto;\n    -khtml-user-select: auto;\n    -moz-user-select: auto;\n    -o-user-select: auto;\n}\n\ninput {\n    color: rgba(255, 255, 255, 0.8);\n    font: $font;\n}\n\ninput::placeholder {\n    color: rgba(255, 255, 255, 0.4);\n    font: $font;\n    \n    @media only screen and (max-width: 670px) {\n        font-size: 30px;\n    }\n}\n\ninput:focus {\n    outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
