import "./App.scss"
import './stars.scss'
import { Soundboards } from "./Pages";


const stars = () => {
  let html = []
  for (let i = 1; i <= 200; i++) {
    html.push(
      <div className="circle-container">
        <div className="circle"></div>
      </div>)
  }
  return html
}

function App() {
  return (<>
    <div id="container">{stars()}</div>
    <Soundboards/>
    
  </>)
}

export default App;
