// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Section_section__Ngwlu {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.Section_section__Ngwlu .Section_header__iXIxA {
  display: flex;
  flex-direction: column;
}
.Section_section__Ngwlu .Section_header__iXIxA div {
  display: flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;
}
.Section_section__Ngwlu .Section_header__iXIxA div img {
  width: 22px;
  height: 22px;
  transition: 0.2s;
}
.Section_section__Ngwlu .Section_header__iXIxA span {
  height: 3px;
  background-color: white;
  border-radius: 10px;
}
.Section_section__Ngwlu .Section_container__VNkUL {
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  grid-row-gap: 40px;
  align-items: center;
  justify-items: center;
}
@media only screen and (max-width: 1940px) {
  .Section_section__Ngwlu .Section_container__VNkUL {
    grid-template-columns: 25% 25% 25% 25%;
  }
}
@media only screen and (max-width: 1580px) {
  .Section_section__Ngwlu .Section_container__VNkUL {
    grid-template-columns: 33% 34% 33%;
  }
}
@media only screen and (max-width: 1250px) {
  .Section_section__Ngwlu .Section_container__VNkUL {
    grid-template-columns: 50% 50%;
  }
}
@media only screen and (max-width: 900px) {
  .Section_section__Ngwlu .Section_container__VNkUL {
    grid-template-columns: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/Section/styles/Section.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;AACJ;AACI;EACI,aAAA;EACA,sBAAA;AACR;AACQ;EACI,aAAA;EACA,mBAAA;EACA,SAAA;EAEA,eAAA;AAAZ;AAEY;EACI,WAAA;EACA,YAAA;EAEA,gBAAA;AADhB;AAOQ;EACI,WAAA;EAEA,uBAAA;EACA,mBAAA;AANZ;AAUI;EACI,aAAA;EACA,0CAAA;EACA,kBAAA;EAEA,mBAAA;EACA,qBAAA;AATR;AAWQ;EARJ;IASQ,sCAAA;EARV;AACF;AAUQ;EAZJ;IAaQ,kCAAA;EAPV;AACF;AASQ;EAhBJ;IAiBQ,8BAAA;EANV;AACF;AAQQ;EApBJ;IAqBQ,2BAAA;EALV;AACF","sourcesContent":[".section {\n    display: flex;\n    flex-direction: column;\n    gap: 40px;\n\n    .header {\n        display: flex;\n        flex-direction: column;\n        \n        div {\n            display: flex;\n            align-items: center;\n            gap: 15px;\n\n            cursor: pointer;\n\n            img {\n                width: 22px;\n                height: 22px;\n\n                transition: 0.2s;\n            }\n\n            //background-color: rgb(20, 20, 166);\n        }\n\n        span {\n            height: 3px;\n\n            background-color: white;\n            border-radius: 10px;\n        }\n    }\n\n    .container {\n        display: grid;\n        grid-template-columns: 20% 20% 20% 20% 20%;\n        grid-row-gap: 40px;\n\n        align-items: center;\n        justify-items: center;\n\n        @media only screen and (max-width: 1940px) {\n            grid-template-columns: 25% 25% 25% 25%;\n        }\n\n        @media only screen and (max-width: 1580px) {\n            grid-template-columns: 33% 34% 33%;\n        }\n\n        @media only screen and (max-width: 1250px) {\n            grid-template-columns: 50% 50%;\n        }\n\n        @media only screen and (max-width: 900px) {\n            grid-template-columns: 100%;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `Section_section__Ngwlu`,
	"header": `Section_header__iXIxA`,
	"container": `Section_container__VNkUL`
};
export default ___CSS_LOADER_EXPORT___;
