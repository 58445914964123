// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SoundboardButton_button__8T4DW, .SoundboardButton_stop_button__U6p7v {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 310px;
  height: 100px;
  border-radius: 25px;
  border: 5px solid rgba(255, 255, 255, 0.7);
  background: rgba(255, 255, 255, 0.1);
  cursor: pointer;
  transition: 0.2s;
}
.SoundboardButton_button__8T4DW img, .SoundboardButton_stop_button__U6p7v img {
  width: 40px;
  height: 40px;
}
.SoundboardButton_button__8T4DW div, .SoundboardButton_stop_button__U6p7v div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  margin: 0px 25px;
}
.SoundboardButton_button__8T4DW div .SoundboardButton_label__4HPcW, .SoundboardButton_stop_button__U6p7v div .SoundboardButton_label__4HPcW {
  max-width: 200px;
  margin: 0;
}
.SoundboardButton_button__8T4DW div .SoundboardButton_label__4HPcW p, .SoundboardButton_stop_button__U6p7v div .SoundboardButton_label__4HPcW p {
  width: 100%;
  max-width: auto;
  max-height: 100%;
  line-height: 1em;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  word-break: break-all;
}`, "",{"version":3,"sources":["webpack://./src/Components/SoundboardButton/styles/SoundboardButton.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EAEA,YAAA;EACA,aAAA;EAEA,mBAAA;EACA,0CAAA;EACA,oCAAA;EAEA,eAAA;EACA,gBAAA;AAFJ;AAII;EACI,WAAA;EACA,YAAA;AAFR;AAMI;EACI,aAAA;EACA,mBAAA;EACA,8BAAA;EAEA,WAAA;EACA,YAAA;EAEA,gBAAA;AANR;AAQQ;EACI,gBAAA;EACA,SAAA;AANZ;AAQY;EACI,WAAA;EACA,eAAA;EACA,gBAAA;EAEA,gBAAA;EACA,oBAAA;EACA,4BAAA;EACA,qBAAA;EACA,gBAAA;EACA,qBAAA;AAPhB","sourcesContent":[".button, .stop_button {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    width: 310px;\n    height: 100px;\n\n    border-radius: 25px;\n    border: 5px solid rgba(255, 255, 255, 0.70);\n    background: rgba(255, 255, 255, 0.10);\n\n    cursor: pointer;\n    transition: 0.2s;\n    \n    img {\n        width: 40px;\n        height: 40px;\n    }\n\n\n    div {\n        display: flex;\n        align-items: center;\n        justify-content: space-between;\n        \n        width: 100%;\n        height: 100%;\n        \n        margin: 0px 25px;\n        \n        .label {\n            max-width: 200px;\n            margin: 0;\n        \n            p {\n                width: 100%;\n                max-width: auto;\n                max-height: 100%;\n\n                line-height: 1em;\n                display: -webkit-box;\n                -webkit-box-orient: vertical;\n                -webkit-line-clamp: 2;\n                overflow: hidden;\n                word-break: break-all;\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `SoundboardButton_button__8T4DW`,
	"stop_button": `SoundboardButton_stop_button__U6p7v`,
	"label": `SoundboardButton_label__4HPcW`
};
export default ___CSS_LOADER_EXPORT___;
