import { useState } from "react"
import styles from "./styles/Section.module.scss"

const Section = ({ name, arrayOfButtons, opened }) => {
    const [state, setState] = useState(opened || false)
    
    return (<>
        <section className={styles.section} id={name || "Section"}>
            <div className={styles.header}>
                <div onClick={e => setState(!state)}>
                    <p className="no_user_select">{name || "Section"}</p>
                    <img style={!state ? {transform: "rotate(0deg)"} : {transform: "rotate(90deg)"}} src="/img/arrow.svg"/>
                </div>
                <span/>
            </div>
            {state &&
                <div className={styles.container}>
                    {arrayOfButtons.map((e) => e)}
                </div>
            }
        </section>
    </>)
}

export default Section